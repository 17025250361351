<template>
  <div class="AreaEcharts_box">
    <div id="AreaEcharts" :style="{   width: '200px', height: '185px'   }">
    </div>
    <div class="num_box">
      <ICountUp class="num" :delay="1000" :endVal="areaEchartsNum" />
      <div class="company_wrap">
        <span class="company">(%)</span>
      </div>
    </div>
    <h5 class="title">租赁情况</h5>
  </div>
</template>

<script>
import ICountUp from 'vue-countup-v2';

export default {
  props: {
    areaEchartsNum: {
      default: () => 0
    }
  },
  components: {
    ICountUp
  },
  methods: {
    drawLine() {
      let AreaEcharts = this.$echarts.init(document.getElementById('AreaEcharts'))
      AreaEcharts.setOption({
        series: [{
          //类型
          type: 'gauge',
          progress: {
            show: true
          },
          //半径
          radius: 75,
          //起始角度。圆心 正右手侧为0度，正上方为90度，正左手侧为180度。
          startAngle: 220,
          //结束角度。  
          endAngle: -40,
          center: ['50%', '50%'],
          //仪表盘轴线相关配置。
          axisLine: {
            show: true,
            color: '#333',
            // 属性lineStyle控制线条样式
            lineStyle: {
              width: 20,
              color: [[this.areaEchartsNum / 100, '#43e8b6'], [1, '#256a55']]
            },
            animationDuration: 3000,
          },
          //分隔线样式。
          splitLine: {
            show: false,
          },
          tooltip: {
            show: false
          },
          //刻度样式。
          axisTick: {
            show: false,
          },
          //刻度标签。
          axisLabel: {
            show: false,
          },
          //仪表盘指针。
          pointer: {
            //这个show属性好像有问题，因为在这次开发中，需要去掉指正，我设置false的时候，还是显示指针，估计是BUG吧，我用的echarts-3.2.3；希望改进。最终，我把width属性设置为0，成功搞定！
            show: false,
            //指针长度
            length: '90%',
            width: 0,
          },
          //仪表盘标题。
          title: {
            show: false,
            offsetCenter: [0, '-40%'], // x, y，单位px
            textStyle: {
              color: '#000',
              fontSize: 30
            }
          },
          //仪表盘详情，用于显示数据。
          detail: {
            show: true,
            offsetCenter: [0, '-10%'],
            formatter: ' ',
            textStyle: {
              fontSize: 30,
              color: '#fff'
            }
          },
          data: [{
            value: '0',
            name: '',
            title: {
              show: false,
              fontSize: 12
            }
          }]
        }]
      })
    }
  },
  mounted() {
    this.drawLine()
  },
}
</script>

<style scoped lang="less">
.AreaEcharts_box {
  position: relative;

  .num_box {
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;

    .num {
      font-size: 35px;

      span {
        line-height: 40px;
      }
    }

    .company_wrap {
      display: flex;
      align-items: flex-end;
    }

    .company {
      font-size: 13px;
      line-height: 24px;
    }

  }

  .title {
    position: absolute;
    bottom: 35px;
    left: 0;
    width: 100%;
    text-align: center;
  }
}
</style>