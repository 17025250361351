<template>
    <div class="zhongguancunHome">
        <!-- <div class="wrap_bg"></div> -->
        <div v-if="$store.state.mapType" class=""></div>
        <!-- <FeiChiHomeLeft :class="{'animate__animated':true, 'animate__fadeInLeftBig': $store.state.showDataValue, 'animate__fadeOutLeftBig': !$store.state.showDataValue}" style="position: relative;z-index: 1000;" />
        <FeiChiHomeRight :class="{'animate__animated':true, 'animate__fadeInRightBig': $store.state.showDataValue, 'animate__fadeOutRightBig': !$store.state.showDataValue}"  style="position: relative;z-index: 1000;" /> -->
        <LeftContent v-if="$store.state.animateDelay && $store.state.mapType"
            :class="{ 'animate__animated': true, 'animate__fadeInLeftBig': $store.state.showDataValue, 'animate__fadeOutLeftBig': !$store.state.showDataValue }"
            style="position: relative;z-index: 1000;" />
        <MainContent v-if="$store.state.animateDelay && $store.state.mapType"
            :class="{ 'animate__animated': true, 'animate__fadeInLeftBig': $store.state.showDataValue, 'animate__fadeOutLeftBig': !$store.state.showDataValue }"
            style="position: relative;z-index: 1000;" />
        <RightContent v-if="$store.state.animateDelay && $store.state.mapType"
            :class="{ 'animate__animated': true, 'animate__fadeInRightBig': $store.state.showDataValue, 'animate__fadeOutRightBig': !$store.state.showDataValue }"
            style="position: relative;z-index: 1000;" />
        <!-- <SearchModel /> -->
        <div class="bg_wrap"></div>
        <!-- <div class="bottom_line"></div> -->
    </div>
</template>

<script>
import '@/assets/css/zhongguancun/zhongguancunHome.less'
import LeftContent from '@/components/zhongguancunHome/LeftContent.vue'
import MainContent from '@/components/zhongguancunHome/MainContent.vue'
import RightContent from '@/components/zhongguancunHome/RightContent.vue'
// import ThreeBtn from '@/components/ThreeBtn.vue'
// import SwitchScreen from '@/components/SwitchScreen.vue'
export default {
    name: 'Home',
    components: {
        LeftContent,
        MainContent,
        RightContent
    },
    data() {
        return {
            screenWidth: document.body.clientWidth,
            scale: document.body.clientWidth / 1920
        }
    },
    mounted() {
        this.$store.dispatch('getHomeData')
        // const that = this
        window.onload = function () {
            // // console.log('~~~~~~', this.store.state)
            adjust()
            window.onresize = function () {
                adjust()
            }
        }
        let adjust = function () {
            let winWidth = document.documentElement.clientWidth
            // console.log(winWidth)
            let body = document.getElementsByTagName('body')[0]
            // let winHeight = document.body.clientHeight
            // 获取屏幕的宽度 和高度
            let scale = winWidth / 1920
            // // console.log(scale)
            body.style.cssText = 'transform:scale(' + scale + ');'
        }
    },
    watch: {
        /* 监听*/
        screenWidth(val) {
            this.screenWidth = val;
            // console.log("this.screenWidth", this.screenWidth)
        }
    },
    computed: {
        scaleFun: function () {
            var scale = this.scale;
            return `transform:scale(${scale})`
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.zhongguancunHome {
    width: 1920px;
    height: 1080px;
    color: #fff;
    position: relative;
    /* background: url('./../assets/images/bg_icon.png') no-repeat; */
    /* background-size: 100% 87.5%; */
}

.wrap_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    opacity: .5;
    /* background: url(../../assets/images/zhongguancun1.png) !important; */
    /* background: radial-gradient(circle, rgba(4, 9, 31, 0) 30%,rgba(4, 9, 31, .7) 50%); */

    /* background: radial-gradient( rgba(4, 9, 31, 0), rgba(4, 9, 31, .2), rgba(4, 9, 31, .5),rgba(4, 9, 31, .9)); */
}

/* .switch_screen_wrap{
      width: 100%;
      height: 60px;
      z-index: 1001;
      position: absolute;
      bottom: 0;
    }
    .switch_screen_wrapBg{
      background: url('./../assets/images/borderBottom.png') no-repeat;
    } */
</style>