<template>
  <div>
    <!-- <div class="pop_up">

    </div> -->
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="less" scoped>
  
</style>