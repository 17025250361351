<template>
  <div class="zhongguancun_right_content">
    <div class="top">
      <ZhongguancunTitle :txt="'园区概况'" />
      <ul class="survey_list">
        <li v-for="(item, index) in survey" :key="index">
          <img :src="item.imgUrl" alt="">
          <div class="survey_list_title">
            <ICountUp class="num" v-if="item.num" :delay="1000" :endVal="item.num" />
            <p class="num" v-if="!item.num">-</p>
            <h5>{{item.title}}<span>({{item.company}})</span></h5>
          </div>
        </li>
      </ul>
      <div class="area_cont">
        <AreaEcharts :areaEchartsNum="areaEchartsNum" v-if="showArea"></AreaEcharts>
        <ul class="area_list">
          <li v-for="(item, index) in areaArr" :key="index">
            <h5>{{item.title}}</h5>
            &nbsp;
            <ICountUp class="num" v-if="item.num" :delay="1000" :endVal="item.num" :options="{decimalPlaces: 2}" />
            &nbsp;
            <p class="num" v-if="!item.num">-</p>
            <span>(m²)</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="bottom">
      <ZhongguancunTitle :txt="'企业概况'" />
      <ul class="profile_list">
        <li :style="{background: 'url('+item.bgUrl+') no-repeat'}" v-for="(item, index) in profileArr" :key="index">
          <img :src="item.iconUrl" alt="">
          <div class="profile_list_box">
            <h5>{{item.title}}</h5>
            <div class="num_box">
              <ICountUp class="num" v-if="item.num" :delay="1000" :endVal="item.num"></ICountUp>
              <p v-if="!item.num" class="num">-</p>
              <span>&nbsp;({{item.company}})</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ZhongguancunTitle from '@/components/ZhongguancunTitle.vue'
import AreaEcharts from '@/components/zhongguancunHome/AreaEcharts.vue'
import ICountUp from 'vue-countup-v2';
import { getOverview } from '@/api/zgc';

export default {
  data() {
    return {
      showArea: false,
      survey: this.$store.state.zhongguancunHome.survey,
      areaArr: this.$store.state.zhongguancunHome.areaArr,
      profileArr: this.$store.state.zhongguancunHome.profileArr,
      areaEchartsNum: this.$store.state.zhongguancunHome.areaEchartsNum
    }
  },
  methods: {
    async getData() {
      const dataName = ['survey', 'areaArr', 'profileArr']
      const res = await getOverview()
      const { data, msg } = res;
      let itemData = {};
      if (msg == 'success' && data.length > 0) {
        itemData = data[0]
        for (let i in itemData) {
          for (let j = 0; j < dataName.length; j++) {
            this[dataName[j]].map((item) => {
              if (i == item.key) {
                item.num = Number(itemData[i])
              }
            })
          }
        }
        this.showArea = true
        this.areaEchartsNum = (Number(itemData.has_rent_area) / Number(itemData.can_rent_area) * 100)
        console.log('园区总览数据', itemData)
      }
    }
  },

  components: {
    ZhongguancunTitle,
    AreaEcharts,
    ICountUp
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="less" scoped>

</style>