<template>
  <div class="zhongguancun_left_content">
    <div class="top">
      <ZhongguancunTitle :txt="'园区介绍'" />
      <p>中关村国际创新大厦位于北大西南角，建筑面积 5.5 万平米, 定位于原创前沿技术聚集区和服务区，是示范区前沿技术培育工作的重要载体，聚焦人工智能、高端芯片、智能机器人、大数据等科技前沿领域企业，着手打造以国际人才为核心的中关村前沿创新 产业平台，有效促进高校院所等前沿技术源头加快成果转化和研发合作，构建全球领先的前沿技术服务生态。 </p>
      <p>本项目拥有会议室服务，员工餐厅、商业设施等多项配套，同 时引入了超市发罗森便利店、瑞幸咖啡等多元化的商业元素，秉承“为您着想、为您服务、让您满意”的宗旨，努力提升服务品质， 为客户营造安全、舒适、高雅的办公环境。</p>
    </div>
    <div class="bottom">
      <ZhongguancunTitle :txt="'项目位置'" />
      <div class="address_cont">
        <!-- <h3 class="project_title">项目四至</h3> -->
        <div class="address_cont_list">
          <div class="address_title" v-for="(item, index) in addressList" :key="index">
            <img :src="item.imgUrl" alt="">
            <!-- <span>图片</span> -->
            <p>{{item.title}}</p>
          </div>
        </div>
      </div>
      <div class="address_map">
        <h4>项目地址: 北京市海淀区北四环西路67号</h4>
        <img src="@/assets/images/zhongguancunLeftMap.png" alt="">
        <!-- @ready="mapHandler" -->
        <!-- <BaiduMap
          style="width: 360px;height: 300px;"
          :scroll-wheel-zoom="true"
          ak="mRfFK6WEIEPrVTqneDufDNTn7hg5Rjk2"
           @ready="mapHandler">
        </BaiduMap> -->
      </div>
    </div>
  </div>
</template>

<script>
import ZhongguancunTitle from '@/components/ZhongguancunTitle.vue'
// import {BaiduMap} from "vue-baidu-map";
// import BmNavigation from  "vue-baidu-map/components/controls/Navigation.vue"
  export default {
    data() {
      return {
        addressList: [
          {
            imgUrl: require('@/assets/images/zhongguancunLeftIcon1.png'),
            title: '东至：海淀新技术大厦'
          },
          {
            imgUrl: require('@/assets/images/zhongguancunLeftIcon2.png'),
            title: '西至：中央党史研究院'
          },
          {
            imgUrl: require('@/assets/images/zhongguancunLeftIcon3.png'),
            title: '南至：北四环西路'
          },
          {
            imgUrl: require('@/assets/images/zhongguancunLeftIcon4.png'),
            title: '北至：海淀区农林委员会'
          },
        ],
         //商家信息(包含坐标信息)
          businessDetail:{},
          //地图数据
          mapData: {
            //中心坐标
            center: { lng: 116.180716, lat: 40.06693 },
            //缩放级别
            zoom: 18
          },
          //BMap类
          BMap: null,
          //地图对象
          map: null
          }
    },
    methods: {
      async mapHandler({ BMap, map }) {
        this.map = new BMap.Point(this.mapData.center.lng, this.mapData.center.lat);
        map.centerAndZoom(this.map, this.mapData.zoom);
        // 创建定位标记
        let marker = new BMap.Marker(
          new BMap.Point(
            this.mapData.center.lng,
            this.mapData.center.lat
          )
        )
        //将标记添加到地图上
        map.addOverlay(marker)
    },

    },
    components: {
      ZhongguancunTitle,
      // BaiduMap,
      // BmNavigation,
    }
  }
</script>

<style lang="less" scoped>

</style>